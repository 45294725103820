setVideo();

function setVideo() {
    let $button = $('.set-video');
    let $video = $('.set-video__container');
    let $title = $('#modal_video .modals__title')

    $button.on('click', function () {
        let title = $(this).data('video-title');
        let src = $(this).data('video-src');
        replaceSource(src);

        $title.text(title)
        $video.attr('src', src);
        $video[0].play();
    });

    $(document).on('stop_video', function () {
        $video[0].pause();
    });

    function replaceSource(src) {
        $video.find('source').remove();
        $('<source src="' + src + '">').appendTo($video);
    }
}