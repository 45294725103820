function scrollAnimation($elem, transform) {

    let top = $elem.offset().top;
    let height = $elem.height();

    checkScrollPosition();

    function checkScrollPosition() {

        let scroll = $(window).scrollTop();


        let percent = (scroll + viewportHeight - top) / ((height + viewportHeight) / 100);

        if (percent < 0) {
            percent = 0;
        } else if (percent > 100) {
            percent = 100;
        }

        transformElem(percent);

        setTimeout(function () {
            requestAnimationFrame(checkScrollPosition);
        }, 200);

    }

    function transformElem(percent) {
        let rotateTransform;
        let scaleTransform;

        if (transform.rotate) {
            rotateTransform = 'rotate(' + (transform.rotate / 100) * percent + 'deg)';
        } else {
            rotateTransform = 'rotate(0deg)';
        }

        if (transform.scale) {
            scaleTransform = 'scale(' + (1 + ((transform.scale / 100) * percent) / 100) + ')';
        } else {
            scaleTransform = 'scale(1)';
        }

        let transformRow = scaleTransform + ' ' + rotateTransform;

        $elem.css('transform', transformRow);
    }
}

$(window).on('load', function () {
    if ($('.background-block__elem').length > 0) {
        scrollAnimation($('.background-block__elem'), {rotate: -20, scale: 20});
    }
    if ($('.main-screen-icons').length > 0) {
        scrollAnimation($('.main-screen-icons__item--1 .main-screen-icons__item-elem'), {rotate: 10, scale: 20});
        scrollAnimation($('.main-screen-icons__item--2 .main-screen-icons__item-elem'), {rotate: 20, scale: 0});
        scrollAnimation($('.main-screen-icons__item--3 .main-screen-icons__item-elem'), {rotate: 30, scale: 0});
        scrollAnimation($('.main-screen-icons__item--4 .main-screen-icons__item-elem'), {rotate: 40, scale: 0});
    }
});