headerScroll();

function headerScroll() {
    let $topHeader = $('.header');
    let scrollClass = 'header--scroll';
    let shift = 109;

    checkHeaderPosition();

    function checkHeaderPosition() {

        if ($(window).scrollTop() > shift) {
            $topHeader.addClass(scrollClass);
            $topHeader.css('transform', 'translateY(-' + shift + 'px)');
        } else {
            $topHeader.removeClass(scrollClass);
            $topHeader.css('transform', 'translateY(-' + $(window).scrollTop() + 'px)');
        }

        requestAnimationFrame(checkHeaderPosition);
    }
}

let catalogMenuName = 'headerMenu';

window.catalogMenu = new menu(
    {
        name: catalogMenuName,
        button: '.header__button',
        buttonActiveClass: 'active',
        menuBlock: '.header__menu',
        menuActiveClass: 'active',
    }
);