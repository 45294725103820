window.modals = new modalToggle();
window.modal_state = false;
let $body = $('body');

// modals = new modalToggle();

function modalToggle() {

    var $open_buttons = $('.open-modal');
    var $close_buttons = $('.close-modal');
    var $background = $('.modals__background');
    var $modals = $('.modals__block');
    var active_class = 'active';
    this.open = function (modal_name) {
        openModal(modal_name);
    };
    this.close = function () {
        closeModal();
    };
    this.state = function () {
        return modal_state;
    };
    $('*').off('click.modals_buttons');
    $open_buttons.on('click.modals_buttons', function () {
        openModal($(this).data('modal'), $(this));
    });

    function openModal(modal_name, button) {
        $body.addClass('opened-modal');
        modal_state = true;
        var $modal = $(modal_name);
        $close_buttons.off('click.modals_buttons');
        $close_buttons.on('click.modals_buttons', function (e) {
            closeModal();
        });
        $background.addClass(active_class);
        $modals.removeClass(active_class);
        $modal.addClass(active_class);
    }

    function closeModal() {
        $body.removeClass('opened-modal');
        modal_state = false;
        $background.removeClass(active_class);
        $background.addClass('close-modal');
        $close_buttons = $('.close-modal');
        $modals.removeClass(active_class);
        $close_buttons.off('click.modals_buttons');
        $(document).trigger('stop_video');
        $close_buttons.on('click.modals_buttons', function (e) {
            closeModal();
        });
    }
}