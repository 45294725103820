$(function () {
    let $slider = $('.background-block__slider');

    let options

    if ($slider.hasClass('background-block__slider--two-images')) {
        options = {
            dots: false,
            nav: true,
            arrows: true,
            loop: true,
            singleItem: true,
            items: 1,
        }
    } else {
        options = {
            dots: true,
            nav: true,
            arrows: true,
            loop: false,
            singleItem: true,
            items: 1,
        }
    }


    $slider.owlCarousel(options);
});